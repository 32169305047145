const supremeFields = [
  {label: "Supreme US Filtered", dataField: "supremeusfiltered"},
  {
    label: "US Filtered Second Webhook IMP: (Please set a different hook in the same channel)",
    dataField: "supremeusfiltered2"
  },
  {
    label: "US Filtered Third Webhook IMP: (Please set a different hook in the same channel)",
    dataField: "supremeusfiltered3"
  },
  {label: "Supreme EU Filtered", dataField: "supremeeufiltered"},
  {
    label: "EU Filtered Extra Webhook IMP: (Please set a different hook in the same channel)",
    dataField: "supremeeufiltered2"
  },
 {label: "Supreme UK Filtered", dataField: "supremeukfiltered"},
  {
    label: "UK Filtered Extra Webhook IMP: (Please set a different hook in the same channel)",
    dataField: "supremeukfiltered2"
  },
  {label: "Supreme JP Filtered", dataField: "supremejpfiltered"},
  {
    label: "JP Filtered Extra Webhook IMP: (Please set a different hook in the same channel)",
    dataField: "supremejpfiltered2"
  },
    {label: "Supreme KR Filtered", dataField: "supremekrfiltered"},
  {
    label: "KR Filtered Extra Webhook IMP: (Please set a different hook in the same channel)",
    dataField: "supremekrfiltered2"
  },
  {label: "Supreme US", dataField: "supremeus"},
  {
    label: "US Extra Webhook IMP: (Please set a different hook in the same channel)",
    dataField: "supremeus2"
  },
  {label: "Supreme EU", dataField: "supremeeu"},
  {
    label: "EU Extra Webhook IMP: (Please set a different hook in the same channel)",
    dataField: "supremeeu2"
  },
    {label: "Supreme UK", dataField: "supremeuk"},
  {
    label: "UK Extra Webhook IMP: (Please set a different hook in the same channel)",
    dataField: "supremeuk2"
  },
  {label: "Supreme JP", dataField: "supremejp"},
  {
    label: "JP Extra Webhook IMP: (Please set a different hook in the same channel)",
    dataField: "supremejp2"
  },
    {label: "Supreme KR", dataField: "supremekr"},
  {
    label: "KR Extra Webhook IMP: (Please set a different hook in the same channel)",
    dataField: "supremekr2"
  }];

export default supremeFields;
