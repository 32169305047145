const adidasFields = [
{label: "Adidas Confirmed App US", dataField: "adidas_confirmed"},
{label: "Adidas Confirmed App EU", dataField: "adidas_confirmed_gb"},
{label: "Adidas Confirmed App JP", dataField: "adidas_confirmed_jp"},
{label: "Adidas Confirmed App KR", dataField: "adidas_confirmed_kr"},
  {label: "Adidas US", dataField: "adidasus"},
  {label: "Adidas CA", dataField: "adidasca"},
  {label: "Adidas EU", dataField: "adidaseu"},
  {label: "Adidas AU", dataField: "adidasau"},
  {label: "Adidas SG", dataField: "adidassg"},
  {label: "Adidas MX", dataField: "adidasmx"},
  {label: "Adidas PH", dataField: "adidasph"},
  {label: "Adidas JP", dataField: "adidasjp"},
  {label: "Adidas KR", dataField: "adidaskr"},
    {label: "Adidas NZ", dataField: "adidasnz"},
    {label: "Adidas MY", dataField: "adidasmy"},
    {label: "Adidas Israel", dataField: "adidasil"},
    {label: "Adidas India", dataField: "adidasin"}

];

export default adidasFields;
